.root {
    display: flex;
    align-items: center;
}

.wrapper {
    //margin: theme.spacing.unit;
    //position: relative;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.6) !important;
}

.progress {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
    background-color: rgba(255, 255, 255, 0.6) !important;
}