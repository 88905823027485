a, button.dropdown-item {
  cursor: pointer;
}

// Custom box filter in dialog component
.dialog {
  .boxFilter {
    top: 0%;
    .panel{
      height: 100vh;
    }
  }
}
//Custom NavDropdown for change module
.modulos-container {
  width: 100%;
  min-width: 290px;
  margin: 0 auto;
  padding: 15px;
}
.module-box {
  padding-bottom: 15px;
  padding-top: 15px;
  cursor: pointer;
  -webkit-transition: all .25s linear;
  transition: all .25s linear;
  &:hover:not(.disabled), &.active:not(.disabled) {
    background-color: rgba(23, 132, 199, 0.90);
    .icon-box, .legend-icon-box{
      color: #fafbfc;
    }
  }
  &.disabled {
    position: relative;
    .icon-box, .legend-icon-box {
      opacity: 0.65;
    }
    &:before {
      opacity: 0;
      -webkit-transition: all .25s linear;
      transition: all .25s linear;
      content: attr(data-textdisabled);
      text-transform: uppercase;
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%,-50%);
      font-size: 12px;
      padding: 5px;
      border: 1px solid #cccccc;
      border-radius: 2px;
      background-color: #ffffff;
      z-index: 2;
      max-width: 100%;
      color: initial;
    }
    &:after {
      content: "";
      -webkit-transition: all .25s linear;
      transition: all .25s linear;
      left: 0;
      top: 0;
      position: absolute;
      z-index: 1;
      opacity: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(34, 34, 34, 0.20);
    }
    &:hover {
      &:before, &:after {
        opacity: 1;
      }
    }
  }
}
.icon-box {
  margin-bottom: 10px;
  color: rgb(73, 80, 87);
}
.legend-icon-box {
  color: #888888;
  padding-left: 10px;
  padding-right: 10px;
  display: block;
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.pad-no, .pad-no .dropdown-menu {
  padding: 0;
}
.pad-custom {
  &:nth-child(odd) {
    padding-right: 0;
  }
  &:nth-child(even) {
    padding-left: 0;
  }
}
.text-ellipis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
//Custom Widget Dashboard
.card.widget{
  cursor: pointer;
  &[disabled]{
    opacity: 0.40!important; filter: grayscale(1); cursor: default;
  }
  &:hover{
    opacity: 0.95;
  }
  .pv-lg.col-8 {
    & > div.text-uppercase {
      min-height: 42px;
    }
  }
}
//Custom Widget Dashboard - listgroup block style
.list-group-block {
  .text-bold {
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
  }

  .list-title-item {
    vertical-align: middle;
    width: calc(100% - 125px);
    overflow: hidden;
    padding-left: 10px;
    padding-right: 10px;
    display: inline-block;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &.list-item-comum{
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

.text-hover-link {
  &:hover {
    span {
      cursor: pointer;
      color: #337ab7;
    }
  }
}

.vertical-middle {
  position: absolute;
  width: 100%;
  top: 0px;
  bottom: 0px;
  margin: auto;
  left: 0px;
  right: 0px;
  display: table;
  text-align: center;
  padding: 5px;
}

.popover {
  max-width: fit-content !important;
  z-index: 13000 !important;
}

.dialog {
  .filter {
    .form-group {
      margin-bottom: 0;
    }
  }
}

.pull-right {
  float: right;
}

.Gantt1{
  .gantt_side_content {
    margin-bottom: 7px;
  }
  .gantt_task_link .gantt_link_arrow {
      margin-top: -3px
  }
  .gantt_side_content.gantt_right {
      bottom: 0;
  }
}

.Gantt2{
  .gantt_task_line, .gantt_line_wrapper {
    margin-top: -9px;
  }
  .gantt_side_content {
      margin-bottom: 7px;
  }
  .gantt_task_link .gantt_link_arrow {
      margin-top: -12px
  }
  .gantt_side_content.gantt_right {
      bottom: 0;
  }
}

.Gantt3{
  .gantt_task_line, .gantt_line_wrapper {
    margin-top: -16px;
  }
  .gantt_side_content {
      margin-bottom: 7px;
  }
  .gantt_task_link .gantt_link_arrow {
      margin-top: -19px
  }
  .gantt_side_content.gantt_right {
      bottom: 0;
  }
}

//custom loading first time
/*html,
body,
#root,
#root > div,
main,
main > div.content-wrapper {
	height: 100%;
}*/

/*Overide z-index tooltip*/
.tooltip {
  &.show {
    z-index: 12000;
  }
}