.nav-tabs>.nav-item>.nav-link {
    font-weight: bold;
    color: $body-color;
    background-color: $gray-lighter;
    margin: 0;
    border: 1px solid $gray;
    border-radius: 0;
    padding: 10px 20px;
    &.active {
        background-color: $nav-tabs-link-active-bg;
        &,
        &:hover,
        &:focus {
            //color: inherit;
            border-bottom-color: #fff;
        }
    }
}

.tab-content {
    padding: 10px 20px;
    border-style: solid;
    border-width: 0 1px 1px 1px;
    border-color: $gray;
    .nav-pills+& {
        border: 0;
        padding: 0;
    }
    .p-0 & {
        padding: 0 !important
    }
}

.list-group {
    line-height: 1.3;
    .list-group-item {
        padding: 10px;
        color: #555;
        &.active {
            color: #fff;
            .badge {
                background-color: #fff;
                color: $primary;
            }
        }
        .card>& {
            border: 0;
        }
    }
}
