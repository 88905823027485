.fundo {
    position: fixed;
    top: 0;
    right: 0;
    content: '';
    width: 100%;
    height: 100%;
    //background-image: url(./../assets/fundo-default.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.main  {
    vertical-align: middle;
    padding: 30px;
    display: inline-block;
}

.contant {
    position: absolute;
    right: 50px;
}

.panel {
    width: 400px;
    //margin-bottom: 45px;
    background: #fff;
    border-radius: 4px;
    padding: 15px;
    min-height: 400px;
}

.imagem {
    text-align: center;
    max-height: 100px;
    max-width:  300px;
    margin-top: 30px;
}

.marginTop30 {
    margin-top: 30px;
}
